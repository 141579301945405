// BRANDS
export const BRANDS = {
  bakarta: {
    id: 'bakarta',
    urlHoreca: 'https://app.bakarta.com/',
    urlMenu: 'https://mybakarta.com/',
    urlPanel: 'https://panel.bakarta.com/'
  },
  schweppes: {
    id: 'schweppes',
    urlHoreca: 'https://horeca.cartadigitalbyschweppes.com/',
    urlMenu: 'https://cartadigitalbyschweppes.com/',
    urlPanel: 'https://panel.cartadigitalbyschweppes.com/'
  }
}
// DB
export const PREFIX_COLLECTIONS = {
  horeca: 'horeca_'
}
// THEMES
export const THEME_BACKGROUND = '#f9fafb'
export const THEME_PRIMARY_COLOR = '#18283b'
export const THEME_SECONDARY_COLOR = '#1bd698'
// VUEX (persistance)
export const VUEX_PERSISTED_KEY = 'bakarta'
// API SERVER
export const URL_API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? 'https://us-central1-bakarta-dev.cloudfunctions.net/onRequest-api'
    : 'http://localhost:7001/bakarta-dev/us-central1/onRequest-api'
// export const URL_API_ENDPOINT =
//   process.env.NODE_ENV === 'production'
//     ? 'https://europe-west1-bakarta-kustovi.cloudfunctions.net/onRequest-api'
//     : 'http://localhost:7001/bakarta-dev/us-central1/onRequest-api'
// APP's URL
export const URL_APP_MENU =
  process.env.NODE_ENV !== 'production'
    ? BRANDS.bakarta.urlMenu
    : 'http://bakarta-menu-dev.web.app/'
export const URL_APP_HORECA =
  process.env.NODE_ENV !== 'production'
    ? BRANDS.bakarta.urlHoreca
    : 'https://horecadev.bakarta.com/'
export const URL_BAKARTA = 'https://bakarta.com/'
// COOKIE
export const BAKARTA_COOKIE = 'bakarta_session_id'
export const BAKARTA_COOKIE_OPTIONS = {
  domain: process.env.NODE_ENV === 'production' ? '.bakarta.com' : null,
  expires: 7,
  ...(process.env.NODE_ENV === 'production' ? { secure: true } : {})
}
// VARIOUS
export const DEFAULT_LANGUAGE = 'es'
export const YOUTUBE_CHANNEL = 'https://www.youtube.com/channel/UClfxK2uZ9euGP9XG1wLzW2g/'
// Activations (types & status)
export const ACTIVATION_TYPES = {
  assistant: 'assistant',
  self: 'self',
  rejected: 'rejected',
  support: 'support',
  noActivation: 'no'
}
export const ACTIVATION_STATUS = {
  done: 'done',
  inProcess: 'inProcess',
  pending: 'pending'
}
// COMPANIES
export const COMPANY_TYPES = {
  brand: 'brand',
  horeca: 'horeca',
  partner: 'partner'
}
export const COMPANY_TYPES_BILLING = {
  person: {
    value: 'person',
    label: 'Persona física'
  },
  selfEmployed: {
    value: 'selfEmployed',
    label: 'Autónomo'
  },
  company: {
    value: 'company',
    label: 'Empresa'
  }
}
// ADDONS
export const PACKAGES = {
  free: {
    icon: 'mdi-rocket-outline',
    label: 'FREE',
    value: 'free'
  },
  lite: {
    icon: 'mdi-star-outline',
    label: 'LITE',
    value: 'lite'
  },
  pro: {
    icon: 'mdi-rocket-launch-outline',
    label: 'PRO',
    value: 'pro'
  },
  advance: {
    icon: 'mdi-earth',
    label: 'ADVANCE',
    value: 'advance'
  }
}
export const ADDONS = {
  advancedManagement: 'advancedManagement',
  bakartaPay: 'bakartaPay',
  bakartaPayPlace: 'bakartaPayPlace',
  company: 'company',
  contact: 'contact',
  crm: 'crm',
  cta: 'cta',
  featuredItems: 'featuredItems',
  imageGallery: 'imageGallery',
  manageUsers: 'manageUsers',
  menusSchedule: 'menusSchedule',
  multiPlaces: 'multiPlaces',
  place: 'place',
  premiumMenu: 'premiumMenu',
  printMenu: 'printMenu',
  takeAway: 'takeAway',
  trackerCovid: 'trackerCovid'
}
export const ADDON_TYPES = {
  addOn: {
    value: 'addOn',
    label: 'Aplicación'
  },
  integration: {
    value: 'integration',
    label: 'integración'
  },
  package: {
    value: 'package',
    label: 'Pack'
  }
}
export const ADDON_PAYMENT = {
  monthly: {
    value: 'monthly',
    label: 'Mensual'
  },
  annually: {
    value: 'annually',
    label: 'Anual'
  }
}
// ROLES
export const ROLES = {
  horeca_admin: {
    label: 'Admin horeca',
    urlHome: '/dashboard',
    value: 'horeca_admin'
  },
  horeca_manager: {
    label: 'Manager horeca',
    urlHome: '/dashboard',
    value: 'horeca_manager'
  },
  partner_brand: {
    label: 'Admin brand',
    urlHome: '/dashboard',
    value: 'partner_brand'
  },
  partner_partner: {
    label: 'Admin partner',
    urlHome: '/dashboard',
    value: 'partner_partner'
  },
  brand_admin: {
    label: 'Admin panel',
    urlHome: '/dashboard',
    value: 'brand_admin'
  }
}
// MENUS
export const MENUS_TYPES = {
  place: {
    label: 'Establecimiento',
    value: 'place'
  },
  takeAway: {
    label: 'Take Away',
    value: 'takeAway'
  }
}
// REFERRAL STATE
export const REFERRALS_STATE = {
  pending: {
    label: 'Pendiente',
    value: 'pending'
  },
  activated: {
    label: 'Activado',
    value: 'activated'
  }
}
// Email de destino para peticiones
export const REQUEST_EMAIL = 'mhernandez@kustovi.com'
// Días periodo de prueba
export const TRIAL_DAYS = 14
// Distintas guías que se poseen por plan
export const GUIDES = {
  free: [
    {
      id: 'firstSteps',
      icon: 'mdi-school',
      path: '/menus'
    },
    {
      id: 'qrCode',
      icon: 'mdi-qrcode',
      path: '/share-menu-link'
    },
    {
      id: 'configContact',
      icon: 'mdi-map-marker',
      path: '/contact-config-place'
    },
    {
      id: 'configWeb',
      icon: 'mdi-earth',
      path: '/config-web'
    },
    {
      id: 'sortMenus',
      icon: 'mdi-sort',
      path: '/menus'
    },
    {
      id: 'menusNotAvailable',
      icon: 'mdi-cancel',
      path: '/menus'
    },
    {
      id: 'allergens',
      icon: 'mdi-peanut',
      path: '/menus'
    },
    {
      id: 'configLanguages',
      icon: 'mdi-translate',
      path: '/config-base'
    },
    {
      id: 'configSchedule',
      icon: 'mdi-clock-outline',
      path: '/contact-config-schedule'
    },
    {
      id: 'rations',
      icon: 'mdi-numeric',
      path: '/rations'
    }
  ],
  lite: [
    {
      id: 'featuredItems',
      icon: 'mdi-bullhorn',
      path: '/featured-items'
    },
    {
      id: 'configMainInformation',
      icon: 'mdi-information-outline',
      path: '/config-base'
    },
    {
      id: 'configAdvanced',
      icon: 'mdi-palette-advanced',
      path: '/config-web'
    },
    {
      id: 'menuSchedule',
      icon: 'mdi-calendar-clock'
    },
    {
      id: 'imageGallery',
      icon: 'mdi-image-multiple-outline'
    }
  ],
  pro: [
    {
      id: 'takeAway',
      icon: 'mdi-tray-full',
      path: '/take-away-orders'
    },
    {
      id: 'crm',
      icon: 'mdi-account-group',
      path: '/crm'
    },
    {
      id: 'team',
      icon: 'mdi-account-multiple',
      path: '/manage-users'
    },
    {
      id: 'featuredItems',
      icon: 'mdi-bullhorn',
      path: '/featured-items'
    },
    {
      id: 'configMainInformation',
      icon: 'mdi-information-outline',
      path: '/config-base'
    },
    {
      id: 'configAdvanced',
      icon: 'mdi-palette-advanced',
      path: '/config-web'
    },
    {
      id: 'menuSchedule',
      icon: 'mdi-calendar-clock'
    },
    {
      id: 'imageGallery',
      icon: 'mdi-image-multiple-outline'
    }
  ]
}
// RELATIONSHIP
export const COMPANY_RELATIONSHIP_TYPES = {
  partner: 'partner',
  brand: 'brand',
  referred: 'referred'
}
export const COMPANY_RELATIONSHIP_STATUS = {
  pending: 'pending',
  accepted: 'accepted',
  rejected: 'rejected',
  canceled: 'canceled'
}
// Limits
export const QUERY_LIMITS = [null, 1, 50, 100, 500, 1000, 2500, 'all']
